<template>
    <div class="content-container">
        <div class="top-content">
            <form @submit.prevent>
                <div class="flex-row-centered">
                    <div class="flex-col-centered">
                        <MarketSelector  enableSE @change=getTableData />
                    </div>
                    <div class="flex-col-centered">
                        <button class="submit-btn" 
                            @click=insurancePopUpActivation()>
                            New Claim
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <Table 
            :Rows="tableFilterApplied? insuranceRowsCopy : insuranceRows" 
            :Cols=cols 
            :Labels=labels 
            SplitColKeys
            :DefaultSortCols=defaultSortCols
            :DefaultSortOrders=defaultSortOrders
        >
            <template #isCompleted-1={value}>
                <button class="table-button" :disabled="value.isCompleted"
                    @click="displayEditPopUp(value)">
                        Edit
                </button>
            </template>
            <template #isCompleted={value}>
                <TickCrossBooleanDisplay :value="value.isCompleted" />
            </template>
            <template #comments="props">
                <div style="display: flex;" v-if="props.value.comments">
                    <button 
                        class="overflow-text"
                        @click="ViewComment(props.value)"
                        :title="`View Full Comment in Popup`"
                    >
                        {{ props.value.comments }}
                        </button>
                </div>
                <div style="display: flex;" v-if="props.value.comments == null">
                    <button
                        class="table-button"
                        @click="ViewComment(props.value)"
                    >
                        Add Comment
                        </button>
                </div>
            </template>
        </Table>
        <InsuranceClaimsPopUp 
            v-model=insurancePopUpStatus 
            :disableDefaultToolTip=true
            :market="marketSelection"
            @confirm="getTableData"
        />
        <InsuranceClaimsEditPopUp
            v-model=selectedRow
            :disableDefaultToolTip=true
            @confirm="getTableData"
        />
        <InsuranceCommentPopUp
            v-model=commentPopUpContent
            @close="newCommentInsertedPopUp=false"
            @confirm="getTableData"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted} from "vue";
// Services
import { insuranceClaims } from "@/services/network";
import InsuranceClaimsPopUp from "@/components/InsuranceClaimsPopUp.vue"
import InsuranceClaimsEditPopUp from "@/components/InsuranceClaimsEditPopUp.vue";
import InsuranceCommentPopUp from "@/components/InsuranceCommentPopUp.vue";
import IInsuranceClaimsGetQuery from "@/Interfaces/InsuranceClaims/IInsuranceClaimsGetQuery"
// Components
import MarketSelector from '@/components/MarketSelector.vue';
// Misc
import { DisplayableKeys } from "@/services/SorterService";
import { marketSelection } from "@/services/helpers/Helper";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
const defaultSortOrders = ref<(1|-1)[]>([-1]);
const defaultSortCols = ref([2]);

// Table
import Table from "@/components/TablePersistentChecking.vue";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import TickCrossBooleanDisplay from "@/components/TickCrossBooleanDisplay.vue";
import IInsuranceForm from "@/Interfaces/InsuranceClaims/IInsuranceClaimsForm";

const cols: DisplayableKeys<IInsuranceForm>[] = [
    "contractId",
    "isCompleted",
    "ticketId",
    "paymentDate",
    "caseReferenceId",
    "fullAmount",
    "excessAmount",
    "companyName",
    "companyVatId",
	"companyLocation",
    "comments",
    "userEmail",
    "isCompleted"
];
const labels= [
    "Contract ID",
    "Completed",
    "Ticket ID",
    "Payment Date",
    "Case Reference ID",
    "Full Amount",
    "Excess Amount",
    "Company Name",
    "Company VAT ID",
	"Company Location",
    "Comments",
    "Reporter",
    "Edit"
]

const working = ref(false);
const insuranceRows =ref<IInsuranceForm[]>();
const insuranceRowsCopy = ref<IInsuranceForm[]>();
const selectedRow = ref<IInsuranceForm>();
const tableFilterApplied = ref(false);
const insurancePopUpStatus = ref(false);
const commentPopUpContent = ref<IInsuranceForm>();
const newCommentInsertedPopUp = ref(false);

onMounted(async () => {
    document.title = "Insurance Claims Export - Optio";
    insuranceRows.value = undefined;
    getTableData()
});

async function getTableData(){
    if(working.value) return;    
    try{
        working.value=true
        insuranceRows.value = undefined;
        const request: IInsuranceClaimsGetQuery = {
            market: marketSelection.value, 
        }
        const response = await insuranceClaims.get(request);
        let responseData = response.data;
        responseData.map(x => x.paymentDate = new DateIgnoreTime(x.paymentDate))
        insuranceRows.value = responseData
    }catch (error) {
        useNotifierWithErrFormatter().error({error});
    }
    working.value=false
}

function displayEditPopUp(row: IInsuranceForm){
    selectedRow.value = row
}

function insurancePopUpActivation(){
    insurancePopUpStatus.value = !insurancePopUpStatus.value;
}

function ViewComment(row: IInsuranceForm){
    commentPopUpContent.value = row;
}
</script>

<style scoped lang="scss">
.top-content {
    padding: 0.5rem;
}
.form-container {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    justify-content: space-evenly;
    justify-items: center;
    align-items: start;
    >*{
        min-width: 10rem;
        width: 100%;
        >*{
            width: 100%;
        }
    }
    :deep(select) {
        width: 100%;
        margin-bottom: 10px;
    }
    :deep(input) {
        width: 100%;
        margin-bottom: 10px;
    }
}
.submit-btn {
    cursor: pointer;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: var(--primary-color);
    }
}
.selection-form-btn {
    height: 2.3rem;
    border-color: var(--primary-color-dark);
    font-weight: bold;
    background-color: var(--primary-color-lighter);
    color: blue;
    cursor: pointer;

    &:hover {
        background-color: var(--primary-color);
        color: white;
    }
}
:deep(.flex-row-centered){
    align-items: end;
}
.overflow-text {
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;
    max-width: 16vw;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
}
.overflow-text:hover {
  cursor: pointer; 
  color: blue; 
  text-decoration: underline; 
}
</style>