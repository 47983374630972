<template>
    <Popup :model-value=!!selectedRow :title="newCommentInsertedPopUp ? `Add Comment For Insurance Claim: ${selectedRow?.contractId}` : `Comment For Insurance Claim: ${selectedRow?.contractId}`" hasClose @update:modelValue=closeChild>
        <template #contents>
            <div class="flex-col-centered">
               <br>
            </div>
            <div class="top-content" >
                <form @submit.prevent="EditInsuranceClaim">
                    <div>
                        <!--comments input field -->
                        <span v-if="editCommentButtonState == false && newCommentInsertedPopUp == false" class="comment-display-style">
                            {{selectedRowCopy.comments}}
                        </span>
                        <textarea v-else  class="form-input textarea-input invalid-input"  maxlength=4096 spellcheck="true" rows="4" id="comments" v-model="selectedRowCopy.comments"/>  
                    </div>
                    <div class="flex-col-centered">
                        <button v-if="itemBeingEdited == false" class="submit-btn" type="submit">
                            {{newCommentInsertedPopUp? 'Add Comment':'Confirm Edit'}}
                        </button>
                        <button v-if="itemBeingEdited == true" class="submit-btn" type="submit" @click="editCommentButtonState = true">
                            Edit Comment
                        </button>
                    </div>
                </form>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, ref, defineProps, watch } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import Popup from "./Popup.vue";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { insuranceClaims } from "@/services/network";
import IInsuranceForm from "@/Interfaces/InsuranceClaims/IInsuranceClaimsForm";
import DateIgnoreTime from "@/types/DateIgnoreTime";
import { authService } from "@/auth/authSingleton";

const props = defineProps<{
    modelValue?: IInsuranceForm;
}>();
const newCommentInsertedPopUp = computed(()=>{
    if(selectedRow.value?.comments == null || selectedRow.value?.comments == ""){
        return true
    }else{
        return false
    }
});
const selectedRow = computed({
    get() {
        return props.modelValue
    },
    set(val) {
        emits('update:modelValue', val);
    }
})
const editCommentButtonState = ref(false);

const itemBeingEdited = computed(()=>{
    if(newCommentInsertedPopUp.value == true){
        return false
    }
    if(editCommentButtonState.value == false){
        return true
    }
    else{
        return false
    }
});
const emits = defineEmits<{
    (e: 'update:modelValue', val?: IInsuranceForm);
    (e: 'confirm');
}>();
const working = ref(false);
const selectedRowCopy = ref<Partial<IInsuranceForm>>({});

watch(selectedRow, (val)=>{
    if (val) {
        selectedRowCopy.value = Object.assign({}, val);
    }
});
function closeChild(){
    selectedRow.value = undefined;
    editCommentButtonState.value = false;
}

async function EditInsuranceClaim() {
    if(working.value) return;    
    const closeSpinner = useSpinnerPopup().show();
    try{
        if(selectedRowCopy.value.comments === selectedRow.value?.comments){
            useNotifierWithErrFormatter().warn({msg: "Comment was not changed"});
        }
        else{
            working.value=true
            let postRequest: IInsuranceForm = {
                market: selectedRowCopy.value?.market ?? 'GB',
                contractId: selectedRowCopy.value?.contractId || '',
                paymentDate: new DateIgnoreTime(selectedRowCopy.value?.paymentDate),
                caseReferenceId: selectedRowCopy.value?.caseReferenceId, 
                fullAmount: selectedRowCopy.value?.fullAmount, 
                excessAmount: selectedRowCopy.value?.excessAmount,
                companyName: selectedRowCopy.value?.companyName, 
                companyVatId: selectedRowCopy.value?.companyVatId, 
                companyLocation: selectedRowCopy.value?.companyLocation,
                comments: selectedRowCopy.value?.comments,
                isCompleted: selectedRowCopy.value?.isCompleted,
                userEmail: authService.getEmailAddress(), 
                ticketId: selectedRowCopy.value?.ticketId
            };        
            await insuranceClaims.put(postRequest)
            useNotifierWithErrFormatter().success({ msg: "Comment successfully edited." });
            emits("confirm");
            closeChild()
        }
        
    }catch(error){
        useNotifierWithErrFormatter().error({error});
    }
    closeSpinner()
    working.value=false
}
</script>

<style scoped lang="scss">
.grid-item-left {
    margin: auto 3px auto auto;
}
.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.5rem;
}
.textarea-input{
    resize: none;
    border-radius: 0.5rem;
    width: 900px;
    min-height: 10em;
    border: var(--border-width) solid var(--primary-color-dark);
}
.comment-display-style{
    white-space: pre-wrap;
    display: block;
    width: 900px;
    font-weight: normal;
    text-align: justify;
    font-size: clamp(0.75rem, 0.75vw, 1.75rem);
}
.submit-btn {
    margin-top: 1rem;
    cursor: pointer;
    border-width: 0px;
    border-radius: var(--buttons-border-radius);
    background-color: var(--primary-color-lighter);
    padding: 10px;
    color: blue;
    font-size: larger;
    font-weight: bold;
    &:hover {
        color: white;
        background-color: var(--primary-color);
    }
}
</style>