<template>
    <div class="flex-col-centered selection">
        <label for="market"><h3 class="header">Market Selection:</h3></label>
        <select id="market"
            v-model="marketSelection"
            @change="emits('change', $event)"
            class="dropdown"
        >
            <option
                v-for="[market,display] in markets"
                :value="market"
                :key="market"
            >
                {{ display }}
            </option>
        </select>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount } from 'vue';
import { marketSelection,  MarketSelection } from '@/services/helpers/Helper';
const emits = defineEmits<{
    (e: 'change', ev: Event): void;
}>();
const props = defineProps<{
    enableSE?: boolean;
}>();

// const props = defineProps<Partial<{
//     enableSE: boolean;
//     enableAll: boolean;
// }>>();
const markets = computed(()=>{
    const results = Object.entries(MarketSelection);
    if (props.enableSE) return results;
    // if(props.enableAll)   return [['ALL', 'All'], ...results];
    return results.filter(([k,v])=>k!=='SE');
});
onBeforeMount(()=>{
    if (!props.enableSE && marketSelection.value=='SE') marketSelection.value = 'GB';
});
</script>
