type KeyReturn<T, K extends keyof T> = T[K] extends string|number ? T[K] : never;
type KeyTypeKeys<T, K extends keyof T> = KeyReturn<T, K> extends never ? never : K;

interface Array<T> {
    groupBy<K extends keyof T>(key: ((x: T)=>string|number)): Record<KeyReturn<T,K>, T[]>;
    groupBy<K extends keyof T>(key: KeyTypeKeys<T,K>): Record<KeyReturn<T,K>, T[]>;
}

Array.prototype.groupBy = function<T, K extends keyof T>(key: KeyTypeKeys<T,K>|((x: T)=>string|number)) {
    if (!(key instanceof Function)) {
        const k = key;
        key = ((x: T)=>x[k] as string|number);
    }
    const accessor = key; // typescript bug with lambda capture
    return this.reduce((rv, x: T)=>{
        const k = accessor(x);
        (rv[k] = rv[k] || []).push(x);
        return rv;
    }, {});
};