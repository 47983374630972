import { AxiosResponse } from "axios";
import NetworkService from "./NetworkService";
import PropCaseHelper from "../helpers/PropCaseHelper";

/** A network service class to change response.data to become camel case
 * only support forcing pascal to camel case at the moment 
 * if first word has more than 2 capital letters, treats it as a single acroynm
*/
export default class FCNetworkService<R, G=undefined, Q=G, D=Q, RD=R> extends NetworkService<R,G,Q,D,RD> {
    override async get(params?: G|Q|null, query?: Q|null): Promise<AxiosResponse<R>> {
        //@ts-ignore
        const result = await super.get(params, query);
        result.data = PropCaseHelper.toCamelObj(result.data);
        return result;
    }
    override async post(params: G|D|null, data?: D): Promise<AxiosResponse<RD, D>> {
        //@ts-ignore
        const result = await super.post(params, data);
        result.data = PropCaseHelper.toCamelObj(result.data);
        return result;
    }
    override async put(params: G|D|null, data?: D): Promise<AxiosResponse<RD, D>> {
        //@ts-ignore
        const result = await super.put(params, data);
        result.data = PropCaseHelper.toCamelObj(result.data);
        return result;
    }
    override async delete(payload?: G|D): Promise<AxiosResponse<RD, D>> {
        //@ts-ignore
        const result = await super.delete(payload);
        result.data = PropCaseHelper.toCamelObj(result.data);
        return result;
    }
}