<template>
    <Popup
        :model-value="!!bankTransferData"
        :title=popUpTitle
        hasClose
        :onClose="closeChild"
    >
        <template #contents>
            <div class="grid-item"> {{ line1Text }}</div>
            <div class="grid-item">
                A sum of <strong> {{ currencyUsed+Math.abs(totalDiff)?.toFixed(2) }} </strong> is
                being transfered from account <strong> {{ transferFrom }} </strong> to
                account <strong> {{ transferTo }} </strong>.
            </div>
            <div class="top-content">
                <span style="padding-bottom: 0.4rem"> {{ line2Text }}</span>
                <span
                    title="Select a date for confirmed ledger tranfers"
                    class="search-with-button"
                >
                    <div class="date-picker" style="width: 50%">
                        <DatePicker
                            v-model="selectedDate"
                            type="date"
                            :enable-time-picker=false
                            placeholder="Select Date..."
                            :text-input-options="textInputOptions"
                            :format="FormatDatePicker"
                            text-input
                            :max-date="maxDate"
                            :teleport="true"
                            required
                        />
                    </div>

                    <button
                        @click="ConfirmButtonAction()"
                        :disabled="selectedDate == undefined"
                        class="right-button"
                    >
                        Confirm
                    </button>
                </span>
            </div>
        </template>
    </Popup>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, Ref } from "vue";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Popup from "@/components/Popup.vue";
import useNotifierWithErrFormatter from "@/services/composables/NotifierWithErrFormatter";
import useSpinnerPopup from "@/services/composables/SpinnerPopup";
import { CurrencySymbol, MarketCurrency, MarketSelection } from "@/services/helpers/Helper";
import IBankTransferPayments from "@/Interfaces/Ledgering/IBankTransferPayments";
import { marketSelection } from "@/services/helpers/Helper";
import IBankTransferApproveOrConfirm from "@/Interfaces/Ledgering/IBankTransferApproveOrConfirm";
import { authService } from "@/auth/authSingleton";
import { approveBankTransferPayment, confirmBankTransferPayment } from "@/services/network";
import DateIgnoreTime from "@/types/DateIgnoreTime";

onMounted(()=>{
    userEmail.value =  authService.getEmailAddress();
})

enum tabs {
    WAITING = "WAITING",
    CONFIRMED = "CONFIRMED",
    COMPLETED = "COMPLETED",
}

const totalDiff = ref<number>(0);
const transferFrom = ref("");
const transferTo = ref("");
const currencyUsed = ref(MarketCurrency.GB);
const line1Text = ref("");
const line2Text = ref("");
const userEmail = ref("");
const working = ref(false);
const selectedDate: Ref<Date | undefined> = ref(undefined);
const textInputOptions = ref({ format: "yyyy/MM/dd" });
const today: Date = new Date();
const maxDate: Date = new Date(today);
maxDate.setDate(today.getDate() + 1);

const props = defineProps<{
    modelValue: IBankTransferPayments | undefined;
    currentTab: tabs | undefined;
}>();
const emits = defineEmits<{
    (e: "update:modelValue");
    (e: "confirm");
}>();
const bankTransferData = computed({
    get() {
        totalDiff.value = props.modelValue?.Amount? props.modelValue?.Amount : 0;
        transferFrom.value = props.modelValue?.AccountFrom? props.modelValue?.AccountFrom : "";
        transferTo.value = props.modelValue?.AccountTo? props.modelValue?.AccountTo : "";
        currencyUsed.value = GetCurrency(props.modelValue?.Market);
        return props.modelValue;
    },
    set(val) {
        emits("update:modelValue");
    },
});
const popUpTitle = computed(()=>{
    if(props.currentTab == tabs.WAITING){
        line1Text.value = "Please confirm transfer."
        line2Text.value = "Select bank transfer confirmation date:"
        return "Transfer Confirmation"
    }else{
        line1Text.value = "Please approve transfer."
        line2Text.value = "Select bank transfer approval date:"
        return "Transfer Approval"
    }
});

async function ConfirmButtonAction() {   
    if (working.value) return;
    working.value = true;
    const closeSpinner = useSpinnerPopup().show();
    try{
        let transferId = bankTransferData?.value?.TransferId
        if (transferId == null) throw new ReferenceError('selectedRow has no valid transfer Id');
        if(selectedDate.value == undefined) throw new ReferenceError('there is no valid selected date');
        if(props.currentTab == undefined) throw new ReferenceError("No confirmation or approval action was selected")
        const payload: IBankTransferApproveOrConfirm = ({
            UserEmail: userEmail.value, 
            BankTransferId: transferId, 
            UserActioned: new DateIgnoreTime(selectedDate.value)
        })
        props.currentTab == tabs.WAITING
        ? await confirmBankTransferPayment.post(payload)
        : await approveBankTransferPayment.post(payload)
        useNotifierWithErrFormatter().success({ msg: props.currentTab == tabs.WAITING? "Payment confirmed." : "Payment approved." });
    }catch (error) {
        useNotifierWithErrFormatter().error({
            name: `Transfer Payment ${props.currentTab == tabs.WAITING? 'Confirmation' : 'Approval'}`,
            errorType: `Error when ${props.currentTab == tabs.WAITING? 'confirming' : 'approving'} transfer payment`,
            error
        });
    } finally {
        closeSpinner();
        emits("confirm");
        bankTransferData.value = undefined;
        working.value = false;
    }
}
function closeChild() {
    clearEntries();
    emits("update:modelValue");
}
const FormatDatePicker = (date: Date) => {
    return date.toLocaleString("sv-SE").split(" ")[0].replaceAll("-", "/");
};
function clearEntries() {
    selectedDate.value = undefined;
}
function GetCurrency(market: keyof typeof marketSelection | undefined){
    return(market != undefined && market != null )? CurrencySymbol[MarketCurrency[market]] :  CurrencySymbol[MarketCurrency['GBP']]
};

</script>

<style scoped>
.grid-item {
    padding: 10px;
}
</style>