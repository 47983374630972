<template>
    <nav class="sidebar" v-if="showComponent">
        <button v-if="showScrollButtons" @click="scrollDown(true)" id="scroll-down" class="scroll-up-button">
            <span class="arrow up"></span>
        </button>
        <div class="menu-bar" :class="{ 'with-scroll-buttons': showScrollButtons }" ref="menuBar">
            <!-- {{ links }} -->
            <!-- {{ authorisedLinks }} -->
            <div class="menu">
                <ul class="menu-links">
                    <li v-for="(link, index) in authorisedLinks" :key="index" :class="{
                        'nav-link': true,
                        'current-page': routeName === link.name || isSubPath(link.name),
                    }">
                        <router-link :to="{ name: link.name }" class="router-link">
                            <span>{{ link.name }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <button v-if="showScrollButtons" @click="scrollDown()" id="scroll-down" class="scroll-down-button">
            <span class="arrow down"></span>
        </button>
    </nav>
</template>

<script setup lang="ts">
import { ref, Ref, inject, onMounted, onUnmounted, computed } from "vue";
import { useRoute } from "vue-router";
import router from "@/router/index";
// Services
import { authService } from "@/auth/authSingleton";
import { AuthorisationLevel, GetAuthLevel } from "../config/AuthConfig";
// Misc
import log from "loglevel";
import { RoutePaths } from "@/router/RouteDetails";

const showComponent = inject("showComponent");
const route = useRoute();

const scrollAmount = ref(200);
const menuBar = ref<HTMLElement | null>(null);

const scrollDown = (reverse: boolean = false) => {
    menuBar.value!.scrollBy({
        top: reverse ? -scrollAmount.value : scrollAmount.value,
        behavior: "smooth",
    });
};

const showScrollButtons = ref<boolean>(false);

onMounted(() => {
    log.trace("onMounted() SideBar");
    log.trace(`Showing links for:\nUser role: ${authService.getUsersRole()} (auth level: ${GetAuthLevel(authService.getUsersRole())})`);

    updateScrollButtons();

    window.addEventListener("resize", updateScrollButtons);
});

onUnmounted(() => {
    log.trace("onUnmounted() SideBar");
    window.removeEventListener("resize", updateScrollButtons);
});

function updateScrollButtons() {
    log.trace("updateScrollButtons() SideBar");
    if (showComponent) {
        log.trace("showComponent", showComponent);
        if (menuBar.value?.scrollHeight && menuBar.value?.clientHeight) {
            if (menuBar.value?.scrollHeight <= menuBar.value?.clientHeight) {
                showScrollButtons.value = false;
            } else {
                showScrollButtons.value = true;
            }
        }
    }
}

interface ILink {
    name: keyof typeof RoutePaths;
}
interface IAuthLevel {
    name: keyof typeof RoutePaths;
    auth: number;
}

const links: Ref<ILink[]> = ref([
    { name: 'Home' },
    { name: 'Journaling' },
    { name: 'Alerts and Warnings' },
    { name: 'EoP Verifications' },
    { name: 'Invoices' },
    { name: 'Data Processing' },
    { name: 'Loan Management' },
    { name: 'Fund Management' },
    { name: 'Consumer Extract' },
    { name: 'Reports' },
    { name: 'Customer & Contract' },
    { name: 'Hire Agreements' },
    { name: 'Contract Validation' },
    { name: 'Contract Approval' },
    { name: 'Car Purchases' },
    { name: 'Sage Backup' },
    { name: 'Fees Configuration' },
    { name: 'VAT Returns' },
    { name: 'Transfer Payments' },
    { name: 'Service Invoice Payments' },
    { name: 'Recharge Invoice Payments' },
    { name: 'Churn Payments' },
    { name: 'VAT Payments' },
    { name: 'Ledger Transactions' },
    { name: 'Funding Reports' },
    { name: 'Insurance Claims' },
    { name: 'Drawdown' },
    { name: 'VAT Loans' },
    { name: 'Portfolio Forecasts' },
]);

const routeAuthLevels: Ref<IAuthLevel[]> = ref(
    router.getRoutes().map((route) => {
        const authLevel = route.meta?.authLevel ?? AuthorisationLevel.NoAuthorisation;
        return { name: route.name, auth: authLevel } as IAuthLevel;
    })
);

function isSubPath(name: keyof typeof RoutePaths) {
    return route.fullPath.startsWith(RoutePaths[name].replace(/\/\:(\w)+\??/g,''));
}

function IsAuthorised(link: ILink): boolean {
    const matchingRoute = routeAuthLevels.value.find(
        (route: IAuthLevel) => route.name === link.name
    );
    const authorised = !matchingRoute?.auth || matchingRoute.auth <= GetAuthLevel(authService.getUsersRole());

    log.trace(`IsAuthorised()\nlink: ${link.name} (requiring auth: ${matchingRoute?.auth})\nUser auth level: ${GetAuthLevel(authService.getUsersRole())}\nAuthorised: ${authorised}`);
    return authorised;
}

const authorisedLinks: Ref<ILink[]> = ref(
    links.value.filter((link: ILink) => IsAuthorised(link))
);
const routeName = computed(()=> route.name == "Car Purchase"? "Car Purchases": route.name )
</script>

<style>
.sidebar {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - var(--default-body-margin) - var(--topbar-height) - calc(var(--margin-bottom-factor) * var(--topbar-sidebar-content-spacing)) - (4 * var(--topbar-sidebar-content-spacing)));
    width: var(--sidebar-width);
    padding: 10px 10px;
    background-color: var(--background-color);
    transition: var(--tran-03);
    z-index: 100;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.05), 5px 5px 10px rgba(0, 0, 0, 0.05);
    border-radius: var(--topbar-sidebar-content-radius);
    margin-right: calc(3 * var(--topbar-sidebar-content-spacing));
    font-size: clamp(0.75rem, 0.75vw, 1.75rem);
}

.sidebar .menu {
    margin-top: 0px;
    margin-bottom: 0px;
}

.sidebar .menu-bar {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.sidebar .menu-bar.with-scroll-buttons {
    height: calc(100% - calc(2 * 4vh) - calc(2 * 6px));
    overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
    display: none;
    /* hide scrollbar for Chromium-based browsers (Chrome and Edge) */
}

.menu-bar {
    scrollbar-width: none;
    /* hide scrollbar for Firefox */
    -ms-overflow-style: none;
    /* hide scrollbar for IE and Edge */
}

.sidebar .text {
    color: var(--text-color);
    transition: var(--tran-03);
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
}

.sidebar ul {
    list-style: none;
    padding: 0 0.2vw 0 0.2vw;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.sidebar li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 0.1em;
    color: blue;
}

.sidebar li:not(.current-page) {
    height: 2em;
}

.sidebar li .router-link {
    list-style: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: var(--sidebar-items-radius);
    text-decoration: none;
    transition: var(--tran-03);
    color: blue;
}

.sidebar li .router-link:hover {
    height: 2.2em;
}

.sidebar li:not(.current-page) .router-link:hover {
    background-color: var(--primary-color);
    color: white;
    padding-left: 1em;
    width: 100%;
}

.sidebar li router-link:hover .text {
    color: var(--sidebar-color);
}

.sidebar li router-link:visited {
    color: inherit;
}

body.dark .sidebar li router-link:hover .text {
    color: var(--text-color);
}

.current-page {
    height: 2.2em;
    font-size: clamp(0.75rem, 0.9vw, 1.75rem);
    font-weight: bold;
    background-color: var(--primary-color-lighter);
    border-radius: var(--sidebar-items-radius);
    padding-left: 1em;
}

.arrow {
    border: solid var(--primary-color);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.scroll-up-button {
    position: absolute;
    background-color: var(--background-color);
    border: none;
    cursor: pointer;
    z-index: 9999;
    width: calc(100% - calc(2 * 10px));
    top: 0;
    margin-top: 6px;
}

.scroll-up-button span {
    display: inline-block;
    vertical-align: middle;
}

.scroll-up-button:hover .arrow {
    padding: 6px;
    border-width: 0 6px 6px 0;
}

.scroll-down-button {
    position: absolute;
    background-color: var(--background-color);
    border: none;
    cursor: pointer;
    z-index: 9999;
    width: calc(100% - calc(2 * 10px));
    bottom: 0;
    margin-bottom: 6px;
}

.scroll-down-button span {
    display: inline-block;
    vertical-align: middle;
}

.scroll-down-button:hover .arrow {
    padding: 6px;
    border-width: 0 6px 6px 0;
}
</style>
