<template>
    <select v-model=nullWrapper class="dropdown" :required=required >
        <option v-if=placeholder value="">{{ placeholder }}</option>
        <option v-for="(k,i) of options" :key=i :value=k >
            <slot :name=k :value=k>{{ k }}</slot>
        </option>
    </select>
</template>

<script setup lang="ts" generic="T, TS extends string | undefined = undefined">
import useVModelDefaultVar from '@/services/composables/VModelDefaultVar';
import {computed} from 'vue';
export type DropdownModelType<T, TS extends string | undefined = undefined> = TS extends undefined ? T : T|null;
export interface DropdownArrayProps<T, TS extends string | undefined = undefined> {
    modelValue?: DropdownModelType<T, TS>;
    placeholder?: TS;
    options: T[];
    required?: boolean;
}
const props = defineProps<
    DropdownArrayProps<T, TS>
>();
const emits = defineEmits<{
    (e:'update:modelValue', val: DropdownModelType<T, TS>)
}>();
const mv = useVModelDefaultVar(props as { modelValue?: T|null }, emits, 'modelValue',  props.modelValue ?? (props.placeholder ? null : props.options[0]));
const nullWrapper = computed({
    get() {
        return mv.value === null ? "" : mv.value;
    },
    set(val: (typeof mv.value | "")) {
        //@ts-expect-error FIX THIS LATER
        mv.value = val === "" ? null : val;
    }
});
const options = computed(()=>[... new Set(props.options)].sort());
</script>

<style scoped lang="scss">
.dropdown {
    min-width: 10rem;
    padding-top: 5px;
    padding-bottom: 5px;
    border: var(--border-width) solid var(--primary-color-dark);
    border-radius: var(--dropdown-radius);
    font-size: medium;
    font-weight: bold;
    color: var(--primary-color-dark);
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}
</style>