<template>
    <div class="center-box">
        <div class="logged-out-logo">
            <img
                class="logo"
                src="../assets/logo.png"
                alt="Optio - Investment Partners"
            />
        </div>
        <button @click="onLogin" class="login-button">LOGIN</button>
    </div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { authService } from "@/auth/authSingleton";
import { useRouter } from "vue-router";
import { IAPILogger } from "@/interfaces/IAPILogger";
import { RoutePaths } from "@/router/RouteDetails";
import { routes } from "@/router";
import { AuthorisationLevel, GetAuthLevel } from "@/config/AuthConfig";

document.title = `Login - Optio`

const router = useRouter();
const Logger: IAPILogger = inject("Logger")!;

function OnLoginRouteExceptions() {
    const transferPaymentRouteElement = routes.find(route => route.name === "Transfer Payments");
    const hireAgreementPdfRouteElement = routes.find(route => route.name === "Hire Agreement (PDF)");

    if (transferPaymentRouteElement) {
        transferPaymentRouteElement.meta = transferPaymentRouteElement.meta || {};
        transferPaymentRouteElement.meta.authLevel = GetAuthLevel(authService.getUsersRole()) === AuthorisationLevel.DepositManager ? AuthorisationLevel.DepositManager : AuthorisationLevel.Analyst;
    }
    if (hireAgreementPdfRouteElement) {
        hireAgreementPdfRouteElement.meta = hireAgreementPdfRouteElement.meta || {};
        hireAgreementPdfRouteElement.meta.authLevel = GetAuthLevel(authService.getUsersRole()) === AuthorisationLevel.External ? AuthorisationLevel.External : AuthorisationLevel.Operator;
    }
}

function onLogin() {
    if (!authService.isLoggedIn()) {
        authService.login().then(() => {
            if (authService.isLoggedIn()) {
                OnLoginRouteExceptions()
                const name = onLogin.name.toString();
                const Message = "Logged in.";
                const Class = name;
                Logger.LogInformation(Message, Class);
                router.push({
                    path: (router.currentRoute.value.query.redirect as string) ?? RoutePaths.Home,
                });
            }
        });
    }
}
</script>

<style>
.center-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.header {
    text-align: center;
}

.login-button {
    margin-top: 20px;
    background-color: black;
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: var(--buttons-border-radius);
    padding: 30px 250px;
}
</style>
@/Interfaces/Logger@/Interfaces/IAPILogger