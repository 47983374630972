<template>
    <Teleport to="#app">
        <div v-if="showing"
            @click=ClosePopup
            :title="disableDefaultToolTip ? '' : 'Click outside of popup to close it.'"
            class="overlay"
        >
            <div @click.stop class="popup" v-bind=$attrs>
                <slot>
                    <div class="title">
                        <slot name="title">
                            {{ title }}
                        </slot>
                    </div>
                    <div class="popup-contents" :class="{ contents:!!$slots['contents'] }">
                        <slot name="contents">
                            {{ contents }}
                        </slot>
                    </div>
                </slot>
            </div>
            <div v-if=hasClose style="text-align: center">
                <button
                    class="popup-button"
                    @click=ClosePopup
                    title="Close popup."
                >
                    Close
                </button>
            </div>
        </div>
    </Teleport>
</template>
<script setup lang="ts">
import { computed } from 'vue';
defineOptions({
  inheritAttrs: false,
});
const props = defineProps<{
    modelValue: boolean;
    title?: string;
    contents?: string;
    hasClose?: boolean;
    disableDefaultToolTip?: boolean;
}>();
const emits = defineEmits<{
    (e:'update:modelValue',val:boolean):void;
    (e:'close'):void;
}>();
const showing = computed({
    get(){
        return props.modelValue;
    },
    set(val:boolean){
        emits('update:modelValue',val);
    }
});
function ClosePopup() {
    showing.value = false;
    emits('close');
}
</script>
<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >.popup {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        max-width: 75%;
        max-height: 75%;
        box-sizing: border-box;
        overflow: auto;
        >.title {
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 1.3rem;
            text-align: center;
        }
        >.popup-contents {
            flex-grow: 1;
            text-align: center;
            input {
                font-size: .8rem;
                border-radius: .4rem;
                padding-left: .6rem;
                padding-top: .2rem;
                padding-bottom: .2rem;
            }
        }
    }
    .popup-button {
        margin-top: 1rem;
        border-radius: var(--table-button-radius);
        border-width: 1px;
        border-color: blue;
        color: blue;
        padding-left: 1.6rem;
        padding-right: 1.6rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        &:hover {
            cursor: pointer;
            background-color: var(--primary-color);
            color: white;
        }
    }
}
</style>